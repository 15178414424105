:root {
  --super-light-blue: #d9f6f8;
  --extra-light-blue: #c9f1f2;
  --light-blue: #68cccb;
  --blue: #006cb7;
  --dark-blue: #2d4e6f;
  --blood-red: #8a2622;
  --white: #ffffff;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

body {
  font-family: "Poppins", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a,
a:link,
a:visited,
a:active {
  text-decoration: none;
  color: inherit;
}
