.not-visible {
  visibility: hidden;
}

.room-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 3rem;
}

.room-container h4 {
  width: 50%;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: var(--dark-blue);
}

.room-name {
  font-size: 1.5rem;
  animation: appearFromTop 700ms ease-out;
}

.room-details {
  display: block;
  font-size: 14px;
  font-weight: normal;
  margin-top: 1rem;
  animation: appearFromBottom 800ms ease-out;
}

.room-arrow {
  font-size: 4rem;
  color: var(--blood-red);
  font-weight: bold;
}

.left-animation .room-arrow {
  animation: arrowAppearFromRight 600ms ease-out;
}

.right-animation .room-arrow {
  animation: arrowAppearFromLeft 600ms ease-out;
}

.room-container img {
  width: 48%;
  height: auto;
}

.left-animation.room-container img {
  animation: imgAppearFromLeft 500ms ease-out;
}

.right-animation.room-container img {
  animation: imgAppearFromRight 500ms ease-out;
}

@media screen and (max-width: 832px) {
  .room-name {
    font-size: 1rem;
    animation: appearFromTop 700ms ease-out;
  }

  .room-details {
    display: block;
    font-size: 10px;
    font-weight: normal;
    margin-top: 1rem;
    animation: appearFromBottom 800ms ease-out;
  }

  .room-arrow {
    font-size: 1rem;
    color: var(--blood-red);
    font-weight: bold;
  }
}

@keyframes imgAppearFromLeft {
  0% {
    opacity: 0;
    transform: translateX(-160px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes imgAppearFromRight {
  0% {
    opacity: 0;
    transform: translateX(160px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes arrowAppearFromLeft {
  0% {
    opacity: 0;
    transform: translateX(-80px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes arrowAppearFromRight {
  0% {
    opacity: 0;
    transform: translateX(80px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes appearFromTop {
  0% {
    opacity: 0;
    transform: translateY(-160px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes appearFromBottom {
  0% {
    opacity: 0;
    transform: translateY(240px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
