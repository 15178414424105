.services-container {
  margin-bottom: 6rem;
}

.slider-item {
  position: relative;
}

.slider-text {
  position: absolute;
  bottom: 10%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 199;
  color: #000000;
  background-color: rgb(202, 241, 242, 0.8);
  color: var(--dark-blue);
  padding: 1rem 3rem;
  font-size: 16px;
  text-transform: uppercase;
  border-radius: 6px;
}

.slider-img {
  transition: 500ms;
}

.slider-img:hover {
  filter: blur(3px) brightness(80%);
  -webkit-filter: blur(3px) brightness(80%);
  transform: scale(1.2);
}

.slider-img img {
  width: 100%;
  height: 40rem;
  object-fit: cover;
  user-select: none;
}

.slider-arrow {
  position: absolute;
  z-index: 99;
  top: calc(50% - 15px);
  cursor: pointer;
  border-radius: 99px;
  width: 3.5rem;
  height: 3.5rem;
  font-size: 20px;
  background-color: rgb(202, 241, 242, 0.8);
  color: var(--dark-blue);
  border: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.slider-arrow:disabled {
  background-color: rgb(202, 241, 242, 0.4);
}

.slider-arrow__left {
  left: 4rem;
}

.slider-arrow__right {
  right: 4rem;
}

@media screen and (max-width: 832px) {
  .slider-img img {
    width: 100%;
    height: 24rem;
    object-fit: cover;
    user-select: none;
  }

  .slider-text {
    padding: 1rem 1.5rem;
    font-size: 10px;
  }

  .slider-arrow {
    width: 2.5rem;
    height: 2.5rem;
    font-size: 16px;
  }

  .slider-arrow__left {
    left: 2rem;
  }

  .slider-arrow__right {
    right: 2rem;
  }
}
