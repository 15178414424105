.about-container {
  padding: 15% 18% 5% 18%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(
    to bottom,
    var(--extra-light-blue),
    var(--super-light-blue),
    var(--white)
  );
  color: var(--dark-blue);
  text-align: center;
  margin-bottom: 4rem;
}

.about-container h1 {
  font-size: 4rem;
}

.brand-highlight {
  background: linear-gradient(
    to right,
    var(--light-blue),
    var(--blue),
    var(--dark-blue)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.about-text {
  margin: 2rem;
  padding: 0 10rem;
  font-size: 18px;
}

@media screen and (max-width: 832px) {
  .about-container {
    padding: 10rem 3rem 4rem 3rem;
    margin-bottom: 1rem;
  }

  .about-container h1 {
    font-size: 2.5rem;
  }

  .about-text {
    margin: 2rem;
    padding: 0 1rem;
    font-size: 16px;
  }
}
