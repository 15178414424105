.section-heading {
  text-align: center;
  font-size: 3rem;
  color: var(--dark-blue);
  text-transform: uppercase;
  margin: 4rem 0;
}

@media screen and (max-width: 832px) {
  .section-heading {
    font-size: 2rem;
  }
}
