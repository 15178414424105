.btn,
.btn:active {
  font-family: inherit;
  font-size: 16px;
  text-transform: uppercase;
  text-decoration: none;
  padding: 15px 40px;
  display: inline-block;
  border: 1px solid var(--blue);
  color: var(--blue);
  background-color: transparent;
  border-radius: 100px;
  transition: all 0.2s;
  cursor: pointer;
}

.btn:hover,
.btn:focus {
  outline: 0;
  transform: translateY(-3px);
  box-shadow: 0 10px 20px rgb(0 0 0 / 20%);
  -webkit-box-shadow: 0 10px 20px rgb(0 0 0 / 20%);
  -moz-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.btn-sm {
  font-size: 14px;
  padding: 12px 36px;
}

@media screen and (max-width: 832px) {
  .btn {
    font-size: 14px;
    padding: 12px 36px;
  }
}
