.contact-container {
  padding: 0 18%;
  background-color: var(--dark-blue);
}

.contact-container h1 {
  color: var(--light-blue);
  padding: 4rem 0;
  margin: 0;
}

.contact-details {
  color: var(--extra-light-blue);
  text-align: center;
}

.contact-details p:not(:last-child) {
  margin-bottom: 1rem;
}

.contact-details p span {
  font-weight: 600;
}

.footer {
  color: var(--extra-light-blue);
  text-align: center;
  padding-top: 6rem;
  padding-bottom: 4rem;
}

@media screen and (max-width: 832px) {
  .contact-container {
    font-size: 14px;
  }
}
