.main-container {
  width: 100%;
  padding: 0 18%;
}

@media screen and (max-width: 832px) {
  .main-container {
    width: 100%;
    padding: 0 3rem;
  }
}
