.navbar {
  position: fixed;
  top: 0%;
  left: 0%;
  width: 100%;
  background: linear-gradient(
    normal,
    var(--extra-light-blue),
    var(--super-light-blue)
  );
  padding: 1rem 0;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  text-align: center;
  z-index: 999;
  transition: 100ms ease;
}

.navbar-shrinked {
  padding: 0.5rem 18%;
  justify-content: space-between;
  box-shadow: 0px 6px 50px -5px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0px 6px 50px -5px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 6px 50px -5px rgba(0, 0, 0, 0.15);
  background-color: var(--super-light-blue);
}

.navbar-shrinked .navbar-brand {
  width: fit-content;
  margin: 0;
}

.navbar-brand {
  width: 100%;
  margin-bottom: 0.5rem;
}

.navbar-brand img {
  height: auto;
  width: 15rem;
}

.navbar-brand h2 {
  font-size: 1.8rem;
  background: linear-gradient(
    to right,
    var(--light-blue),
    var(--blue),
    var(--dark-blue)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.navbar-items ul {
  list-style: none;
  color: var(--dark-blue);
}

.navbar-items ul li {
  display: inline-block;
  font-weight: 600;
  position: relative;
}

.navbar-items ul li:not(:last-child) {
  margin-right: 4rem;
}

.navbar-shrinked .navbar-items ul li:not(:last-child) {
  margin-right: 2rem;
}

.navbar-items ul li::after {
  content: "";
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 0.2em;
  background-color: var(--blood-red);
  transition: opacity 300ms, transform 300ms;
  opacity: 0;
  transform: scale(0);
  transform-origin: center;
}

.navbar-items ul li:hover::after,
.navbar-items ul li:focus::after {
  opacity: 1;
  transform: scale(1);
}

@media screen and (max-width: 1182px) {
  .navbar-shrinked {
    padding: 0.5rem 4%;
    justify-content: space-between;
  }
}

@media screen and (max-width: 832px) {
  .navbar {
    padding: 2rem;
    justify-content: space-between;
  }

  .navbar-shrinked {
    padding: 1rem 3rem;
  }

  .navbar-brand {
    width: fit-content;
    margin: 0;
  }

  .navbar-brand h2 {
    font-size: 1.4rem;
  }

  .navbar-items ul li:not(:last-child),
  .navbar-shrinked .navbar-items ul li:not(:last-child) {
    margin-right: 1rem;
  }
}
